<template>
  <div :class="{ 'is-sp': $vuetify.breakpoint.xs }">
    <v-card>
      <v-card-title
        >マイタスク（{{ $store.state.loginInfo.name }}）</v-card-title
      >
      <Loading v-if="isUserLoading" />
      <p v-else-if="$store.state.loginInfo === null">通信エラー</p>
      <v-card-text v-else>
        <section class="user_info">
          <v-card-subtitle>ユーザー情報</v-card-subtitle>
          <ul>
            <li class="mb-5 mr-3">
              <v-avatar size="40px">
                <v-img
                  v-if="!$store.state.loginInfo.img"
                  :src="require('@/assets/images/user.png')"
                ></v-img>
                <v-img
                  v-else-if="$store.state.picture"
                  :src="
                    VUE_APP_BACK_URL + '/storage/images/' + $store.state.picture
                  "
                ></v-img>
                <v-img
                  v-else
                  :src="
                    VUE_APP_BACK_URL +
                    '/storage/images/' +
                    $store.state.loginInfo.img
                  "
                ></v-img>
              </v-avatar>
            </li>
            <li>
              <v-text-field
                label="名前"
                :value="$store.state.loginInfo.name"
                prepend-inner-icon="mdi-account"
                dense
                readonly
                outlined
                color="primary"
                class="mr-2"
              ></v-text-field>
            </li>
            <li>
              <v-text-field
                label="メールアドレス"
                :value="$store.state.loginInfo.email"
                prepend-inner-icon="mdi-email"
                dense
                readonly
                outlined
                color="primary"
                class="mr-2"
              ></v-text-field>
            </li>
            <li style="width: 250px" class="mr-2">
              <v-select
                :value="$store.state.loginInfo.team_id"
                :items="TEAM"
                label="チーム"
                :item-value="id"
                item-text="label"
                prepend-inner-icon="mdi-account-supervisor-circle"
                outlined
                dense
                color="primary"
                readonly
              ></v-select>
            </li>
            <li style="width: 250px">
              <v-select
                :value="$store.state.loginInfo.attribute"
                :items="ATTRIBUTE"
                label="属性"
                :item-value="id"
                item-text="label"
                prepend-inner-icon="mdi-badge-account-horizontal"
                outlined
                dense
                color="primary"
                readonly
              ></v-select>
            </li>
            <v-spacer></v-spacer>
            <li>
              <v-btn
                :disabled="isUserLoading"
                @click="
                  $router.push(
                    `/main/user/update?userId=${$store.state.loginInfo.id}`
                  )
                "
                color="orange"
                class="orange white--text"
              >
                <span>編集</span>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </li>
          </ul>
        </section>
        <v-divider class="divider"></v-divider>
        <section>
          <v-card-subtitle
            >タスク情報（{{ $store.state.loginInfo.name }}）</v-card-subtitle
          >
          <div class="px-4">
            <section>
              <ul class="d-flex align-center mb-3">
                <li class="mr-3" style="width: 180px">
                  <v-text-field
                    :value="keyword"
                    @input="onChangeKeyword"
                    label="タスク名"
                    placeholder="タスク名を入力"
                    prepend-inner-icon="mdi-magnify"
                    validate-on-blur
                    required
                    outlined
                    dense
                    color="primary"
                  ></v-text-field>
                </li>
                <li style="width: 180px">
                  <v-text-field
                    :value="startDate"
                    @click="startDateDialog = true"
                    @click:clear="onChangeStart"
                    label="開始日"
                    placeholder="指定なし"
                    prepend-inner-icon="mdi-calendar"
                    color="primary"
                    readonly
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                  <v-dialog
                    :value="startDateDialog"
                    @click:outside="startDateDialog = false"
                  >
                    <v-date-picker
                      :value="startDate"
                      @change="onChangeStartDate"
                      color="primary"
                      locale="ja"
                    ></v-date-picker>
                  </v-dialog>
                </li>
                <li>~</li>
                <li style="width: 180px">
                  <v-text-field
                    :value="endDate"
                    @click="endDateDialog = true"
                    @click:clear="onChangeEnd"
                    label="期限日"
                    placeholder="指定なし"
                    prepend-inner-icon="mdi-calendar"
                    color="primary"
                    readonly
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                  <v-dialog
                    :value="endDateDialog"
                    @click:outside="endDateDialog = false"
                  >
                    <v-date-picker
                      :value="endDate"
                      @change="onChangeEndDate"
                      color="primary"
                      locale="ja"
                    ></v-date-picker>
                  </v-dialog>
                </li>
                <li class="pb-1" style="width: 50px">
                  <v-col>
                    <v-btn
                      @click="dayAsc()"
                      :class="dayAscBtn"
                      class="mb-1"
                      block
                      style="width: 5px; height: 15px"
                      >昇</v-btn
                    >
                    <v-btn
                      @click="dayDes()"
                      :class="dayDesBtn"
                      block
                      style="width: 5px; height: 15px"
                      >降</v-btn
                    >
                  </v-col>
                </li>
                <v-spacer></v-spacer>
                <li class="d-flex align-center">
                  <p class="mr-1">状態：</p>
                  <v-btn-toggle
                    :value="statusId"
                    @change="onChangeStatus"
                    multiple
                    v-for="status in STATUS"
                    :key="status.value"
                    :class="status.class"
                    class="mr-1"
                  >
                    <v-btn :value="status.value">
                      {{ status.label }}
                    </v-btn>
                  </v-btn-toggle>
                  <div style="width: 50px">
                    <v-col>
                      <v-btn
                        @click="statusAsc()"
                        :class="statusAscBtn"
                        class="mb-1"
                        block
                        style="width: 5px; height: 12px"
                        >昇</v-btn
                      >
                      <v-btn
                        @click="statusDes()"
                        :class="statusDesBtn"
                        block
                        style="width: 5px; height: 12px"
                        >降</v-btn
                      >
                    </v-col>
                  </div>
                </li>
              </ul>
            </section>
            <section>
              <ul class="d-flex align-center mb-8">
                <li class="mr-3" style="width: 180px">
                  <v-select
                    :items="formCategories"
                    :value="categoryId"
                    @change="onChangeCategory"
                    label="案件名"
                    item-value="category_id"
                    item-text="category_name"
                    prepend-inner-icon="mdi-shape"
                    outlined
                    dense
                    color="primary"
                    multiple
                  >
                  </v-select>
                </li>
                <li class="mr-3" style="width: 180px">
                  <v-select
                    :items="formUserRequesterData"
                    :value="requesterId"
                    @change="onChangeRequester"
                    label="依頼者"
                    item-value="id"
                    item-text="name"
                    prepend-inner-icon="mdi-account"
                    outlined
                    dense
                    color="primary"
                    multiple
                  ></v-select>
                </li>
                <li
                  v-if="requesterId.includes(-1)"
                  class="mr-3"
                  style="width: 180px"
                >
                  <v-text-field
                    :value="userKeyword"
                    @input="onChangeUserKeyword"
                    label="依頼者記入"
                    placeholder="依頼者名を入力"
                    prepend-inner-icon="mdi-magnify"
                    validate-on-blur
                    required
                    outlined
                    dense
                    color="primary"
                  ></v-text-field>
                </li>
                <v-spacer></v-spacer>
                <li class="d-flex align-center mr-8">
                  <p class="mr-1">優先度：</p>
                  <v-btn-toggle
                    :value="priorityId"
                    @change="onChangePriority"
                    multiple
                    v-for="priority in PRIORITIES"
                    :key="priority.value"
                    :class="priority.class"
                    class="mr-1"
                  >
                    <v-btn :value="priority.value">
                      {{ priority.label }}
                    </v-btn>
                  </v-btn-toggle>
                  <div style="width: 50px">
                    <v-col>
                      <v-btn
                        @click="priorityAsc()"
                        :class="priorityAscBtn"
                        class="mb-1"
                        block
                        style="width: 5px; height: 12px"
                        >昇</v-btn
                      >
                      <v-btn
                        @click="priorityDes()"
                        :class="priorityDesBtn"
                        block
                        style="width: 5px; height: 12px"
                        >降</v-btn
                      >
                    </v-col>
                  </div>
                </li>
              </ul>
            </section>
          </div>
          <v-card outlined style="border: 1px solid rgba(0, 0, 0, 0.3)">
            <TaskList :tasks="tasks" />
            <Loading v-if="isLoading" />
            <p v-else-if="tasks === null" class="text-center">通信エラー</p>
            <p v-else-if="!tasks.length" class="text-center">
              データはありません
            </p>
            <div v-else>
              <v-divider></v-divider>
              <div class="d-flex justify-center">
                <v-btn
                  v-if="addTaskShow"
                  @click="addTaskRead"
                  text
                  color="primary"
                  class="my-2"
                  >続きを読み込む</v-btn
                >
                <v-btn
                  v-if="closeTaskShow"
                  @click="closeTaskRead"
                  text
                  color="primary"
                  class="my-2"
                  >閉じる</v-btn
                >
              </div>
            </div>
          </v-card>
        </section>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Loading from "@/components/Loading.vue";
import { myAxios } from "@/plugins/axios";
import TaskList from "@/components/TaskList.vue";
import dayjs from "dayjs";
import { PRIORITIES } from "@/plugins/const";
import { STATUS } from "@/plugins/const";
import { TEAM } from "@/plugins/const";
import { ATTRIBUTE } from "@/plugins/const";
export default {
  components: {
    Loading,
    TaskList,
  },
  data() {
    return {
      TEAM: TEAM,
      ATTRIBUTE: ATTRIBUTE,
      STATUS: STATUS,
      PRIORITIES: PRIORITIES,
      user: {},
      isLoading: false,
      isUserLoading: false,
      paginate: { currentPage: 1 },
      addTaskShow: true,
      closeTaskShow: false,
      tasks: [],
      VUE_APP_BACK_URL: process.env.VUE_APP_BACK_URL,
      priorityId: [0],
      statusId: [0],
      categoryId: [0],
      tagId: 0,
      requestedId: [0],
      requesterId: [0],
      userKeyword: "",
      startDateDialog: false,
      endDateDialog: false,
      startDate: "",
      endDate: "",
      targetMonth: dayjs(),
      keyword: "",
      isStatusAsc: false,
      isStatusDes: false,
      isPriorityAsc: false,
      isPriorityDes: false,
      isDayAsc: false,
      isDayDes: false,
      isOrder: "",
    };
  },
  computed: {
    formCategories() {
      return [
        {
          category_id: 0,
          category_name: "全て",
        },
      ].concat(this.$store.state.categories);
    },
    formTags() {
      return [
        {
          tag_id: 0,
          tag_name: "全て",
        },
      ].concat(this.$store.state.tags);
    },
    formUserData() {
      return [
        {
          id: 0,
          name: "全て",
        },
      ].concat(this.$store.state.users);
    },
    formUserRequesterData() {
      return [
        {
          id: -1,
          name: "取引先",
        },
        {
          id: 0,
          name: "全て",
        },
      ].concat(this.$store.state.users);
    },
    dayAscBtn() {
      return {
        red: this.isDayAsc,
      };
    },
    dayDesBtn() {
      return {
        blue: this.isDayDes,
      };
    },
    statusAscBtn() {
      return {
        red: this.isStatusAsc,
      };
    },
    statusDesBtn() {
      return {
        blue: this.isStatusDes,
      };
    },
    priorityAscBtn() {
      return {
        red: this.isPriorityAsc,
      };
    },
    priorityDesBtn() {
      return {
        blue: this.isPriorityDes,
      };
    },
  },
  methods: {
    dayAsc() {
      if (!this.isDayAsc) {
        this.isDayAsc = true;
        this.isDayDes = false;
        this.isStatusAsc = false;
        this.isStatusDes = false;
        this.isPriorityAsc = false;
        this.isPriorityDes = false;
        this.isOrder = "isDayAsc";
        this.getTasks();
      } else {
        this.isDayAsc = false;
        this.isOrder = "";
        this.getTasks();
      }
    },
    dayDes() {
      if (!this.isDayDes) {
        this.isDayDes = true;
        this.isDayAsc = false;
        this.isStatusAsc = false;
        this.isStatusDes = false;
        this.isPriorityAsc = false;
        this.isPriorityDes = false;
        this.isOrder = "isDayDes";
        this.getTasks();
      } else {
        this.isDayDes = false;
        this.isOrder = "";
        this.getTasks();
      }
    },
    statusAsc() {
      if (!this.isStatusAsc) {
        this.isStatusAsc = true;
        this.isStatusDes = false;
        this.isDayDes = false;
        this.isDayAsc = false;
        this.isPriorityAsc = false;
        this.isPriorityDes = false;
        this.isOrder = "isStatusAsc";
        this.getTasks();
      } else {
        this.isStatusAsc = false;
        this.isOrder = "";
        this.getTasks();
      }
    },
    statusDes() {
      if (!this.isStatusDes) {
        this.isStatusDes = true;
        this.isStatusAsc = false;
        this.isDayDes = false;
        this.isDayAsc = false;
        this.isPriorityAsc = false;
        this.isPriorityDes = false;
        this.isOrder = "isStatusDes"
        this.getTasks();
      } else {
        this.isStatusDes = false;
        this.isOrder = "";
        this.getTasks();
      }
    },
    priorityAsc() {
      if (!this.isPriorityAsc) {
        this.isPriorityAsc = true;
        this.isPriorityDes = false;
        this.isDayDes = false;
        this.isDayAsc = false;
        this.isStatusDes = false;
        this.isStatusAsc = false;
        this.isOrder = "isPriorityAsc"
        this.getTasks();
      } else {
        this.isPriorityAsc = false;
        this.isOrder = "";
        this.getTasks();
      }
    },
    priorityDes() {
      if (!this.isPriorityDes) {
        this.isPriorityDes = true;
        this.isPriorityAsc = false;
        this.isDayDes = false;
        this.isDayAsc = false;
        this.isStatusDes = false;
        this.isStatusAsc = false;
        this.isOrder = "isPriorityDes";
        this.getTasks();
      } else {
        this.isPriorityDes = false;
        this.isOrder = "";
        this.getTasks();
      }
    },
    onChangePriority(id) {
      if (id[id.length - 1] === 0) {
        id = [0];
      } else if (id[id.length - 1] === undefined) {
        id = [0];
      } else {
        id = id.filter((x) => x !== 0);
      }
      this.priorityId = id;
      this.getTasks();
    },
    onChangeStatus(id) {
      if (id[id.length - 1] === 0) {
        id = [0];
      } else if (id[id.length - 1] === undefined) {
        id = [0];
      } else {
        id = id.filter((x) => x !== 0);
      }
      this.statusId = id;
      this.getTasks();
    },
    onChangeCategory(id) {
      if (id[id.length - 1] === 0) {
        id = [0];
      } else if (id[id.length - 1] === undefined) {
        id = [0];
      } else {
        id = id.filter((x) => x !== 0);
      }
      this.categoryId = id;
      this.getTasks();
    },
    onChangeTag(id) {
      this.tagId = id;
      this.getTasks();
    },
    onChangeRequester(id) {
      this.userKeyword = "";
      if (id[id.length - 1] === 0) {
        id = [0];
      } else if (id[id.length - 1] === -1) {
        id = [-1];
      } else if (id[id.length - 1] !== -1 && id[0] === -1) {
        id.shift();
      } else if (id[id.length - 1] === undefined) {
        id = [0];
      } else {
        id = id.filter((x) => x !== 0);
      }
      this.requesterId = id;
      this.getTasks();
    },
    onChangeUserKeyword(name) {
      this.userKeyword = name;
      this.getTasks();
    },
    onChangeKeyword(id) {
      this.keyword = id;
      this.getTasks();
    },
    onChangeStartDate(id) {
      this.startDate = id;
      this.startDateDialog = false;
      this.getTasks();
    },
    onChangeStart() {
      this.startDate = null;
      this.startDateDialog = false;
      this.getTasks();
    },
    onChangeEndDate(id) {
      this.endDate = id;
      this.endDateDialog = false;
      this.getTasks();
    },
    onChangeEnd() {
      this.endDate = null;
      this.endDateDialog = false;
      this.getTasks();
    },
    isBlue(day, start, end) {
      return (
        dayjs(start).format("YYYY-MM-DD") <= dayjs(day).format("YYYY-MM-DD") &&
        dayjs(end).format("YYYY-MM-DD") >= dayjs(day).format("YYYY-MM-DD")
      );
    },
    async getUser() {
      this.isUserLoading = true;
      const requestConfig = {
        url: "/user",
        method: "GET",
        params: {
          id: this.$store.state.loginInfo.id,
        },
      };
      myAxios(requestConfig)
        .then((res) => {
          this.user = res.data;
          this.getTasks();
        })
        .finally(() => {
          this.isUserLoading = false;
        });
    },
    async getTasks() {
      const requestConfig = {
        url: "/tasks",
        method: "GET",
        params: {
          page: 1,
          requestedUserId: [this.$store.state.loginInfo.id],
          priority: this.priorityId,
          status: this.statusId,
          categoryId: this.categoryId,
          requesterUserId: this.requesterId,
          userKeyword: this.userKeyword,
          dateStart: this.startDate,
          dateEnd: this.endDate,
          isPerfect: false,
          keyword: this.keyword,
          assignBoolean: 1,
          order: this.isOrder,
        },
      };
      myAxios(requestConfig).then((res) => {
        this.paginate = res.data.paginate;
        this.tasks = res.data.tasks;
        if (this.paginate.currentPage === this.paginate.lastPage) {
          this.addTaskShow = false;
        } else {
          this.addTaskShow = true;
          this.closeTaskShow = false;
        }
      });
    },
    async addTaskRead() {
      this.isLoading = true;
      const requestConfig = {
        url: "/tasks",
        method: "GET",
        params: {
          page: this.paginate.currentPage + 1,
          requestedUserId: [this.user.id],
          priority: this.priorityId,
          status: this.statusId,
          categoryId: this.categoryId,
          requesterUserId: this.requesterId,
          userKeyword: this.userKeyword,
          dateStart: this.startDate,
          dateEnd: this.endDate,
          isPerfect: false,
          keyword: this.keyword,
          assignBoolean: 1,
          order: this.isOrder,
        },
      };
      myAxios(requestConfig)
        .then((res) => {
          this.paginate = res.data.paginate;
          this.tasks = this.tasks.concat(res.data.tasks);
          if (this.paginate.currentPage === this.paginate.lastPage) {
            this.addTaskShow = false;
            this.closeTaskShow = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    closeTaskRead() {
      this.paginate.currentPage = 1;
      this.tasks.splice(20, this.paginate.total - 20);
      this.addTaskShow = true;
      this.closeTaskShow = false;
    },
  },
  async mounted() {
    this.isUserLoading = true;
    this.getTasks();
    await this.$store.dispatch("getUsers");
    this.getUser();
    this.$store.dispatch("getCategories");
    this.isUserLoading = false;
  },
};
</script>
  
<style lang="scss" scoped>
.user_info {
  ul {
    display: flex;
    li {
      display: flex;
    }
  }
}
.is-sp {
  .user_info {
    ul {
      display: block;
      li {
        justify-content: center;
      }
    }
  }
}
.v-card__subtitle {
  padding: 5px 10px 16px;
}
.divider {
  margin: 20px;
}
.red {
  background-color: red;
  color: white;
}
.blue {
  background-color: blue;
  color: white;
}
.mdi-account-supervisor-circle::before {
  font-size: 28px;
}
::v-deep {
  .v-input--radio-group__input .red--text {
    .theme--light.v-label {
      color: red;
    }
  }
  .v-input--radio-group__input .orange--text {
    .theme--light.v-label {
      color: orange;
    }
  }
  .v-input--radio-group__input .light-green--text {
    .theme--light.v-label {
      color: yellowgreen;
    }
  }
  .v-input--radio-group__input .green--text {
    .theme--light.v-label {
      color: green;
    }
  }
  .v-input--radio-group__input .blue--text {
    .theme--light.v-label {
      color: blue;
    }
  }
  .v-input--radio-group__input .amber--text {
    .theme--light.v-label {
      color: #ffc107;
    }
  }
  .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
    height: 40px;
    border-radius: 10%;
    background-color: #fff;
  }
  .v-select__selections {
    max-height: 40px;
  }
  .v-select__selection--comma:first-child {
    display: block;
  }
  .v-select__selection--comma {
    display: none;
  }
}
</style>